var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$isPrint),expression:"!$isPrint"}],style:({
    backgroundColor: _vm.themeConfig.appBar.background,
    color: _vm.themeConfig.appBar.textColor,
  })},[_c('header',[_c('SkipNavigationLink'),_c('UiToolbar',{staticClass:"app-bar",style:({
        height: _vm.topBarHeight + 'px',
        color: _vm.themeConfig.appBar.textColor,
      }),attrs:{"removeNavIcon":_vm.showAppNavView,"type":"clear","textColor":_vm.themeConfig.appBar.textColor,"raised":false,"removeBrandDivider":true},scopedSlots:_vm._u([(!_vm.showAppNavView)?{key:"icon",fn:function(){return [_c('KIconButton',{attrs:{"icon":"menu","color":_vm.themeConfig.appBar.textColor,"ariaLabel":_vm.$tr('openNav')},on:{"click":function($event){return _vm.$emit('toggleSideNav')}}})]},proxy:true}:null,{key:"brand",fn:function(){return [(_vm.themeConfig.appBar.topLogo)?_c('img',{class:_vm.showAppNavView ? 'brand-logo-left' : 'brand-logo',style:(_vm.themeConfig.appBar.topLogo.style),attrs:{"src":_vm.themeConfig.appBar.topLogo.src,"alt":_vm.themeConfig.appBar.topLogo.alt}}):_vm._e()]},proxy:true},(_vm.showNavigation && _vm.windowIsLarge)?{key:"navigation",fn:function(){return [_vm._t("sub-nav",function(){return [(_vm.links.length > 0)?_c('Navbar',{attrs:{"navigationLinks":_vm.links}}):_vm._e()]})]},proxy:true}:null,{key:"actions",fn:function(){return [_c('div',{style:({
            paddingBottom: '6px',
          }),attrs:{"aria-live":"polite"}},[_vm._t("app-bar-actions"),(_vm.isLearner)?_c('span',[_c('KIcon',{ref:"pointsButton",attrs:{"icon":"pointsActive","ariaLabel":_vm.$tr('pointsAriaLabel'),"color":_vm.$themeTokens.primary}}),(!_vm.windowIsSmall)?_c('div',{staticClass:"points-description"},[_vm._v("\n              "+_vm._s(_vm.$formatNumber(_vm.totalPoints))+"\n            ")]):_vm._e(),(_vm.pointsDisplayed)?_c('div',{staticClass:"points-popover",style:({
                color: _vm.$themeTokens.text,
                padding: '8px',
                backgroundColor: _vm.$themeTokens.surface,
              })},[_vm._v("\n              "+_vm._s(_vm.$tr('pointsMessage', { points: _vm.totalPoints }))+"\n            ")]):_vm._e()],1):_vm._e(),(_vm.isUserLoggedIn)?_c('span',{attrs:{"tabindex":"-1"}},[_c('KIcon',{style:({
                fill: _vm.themeConfig.appBar.textColor,
                height: '24px',
                width: '24px',
                margin: '4px',
                top: '8px',
              }),attrs:{"icon":"person"}}),_c('span',{staticClass:"username"},[_vm._v("\n              "+_vm._s(_vm.usernameForDisplay)+"\n            ")])],1):_vm._e()],2)]},proxy:true}],null,true)},[_c('KTextTruncator',{attrs:{"text":_vm.windowIsSmall ? _vm.truncateText(_vm.title, 20) : _vm.truncateText(_vm.title, 50),"maxLines":1}})],1)],1),(_vm.showNavigation && !_vm.windowIsLarge && !_vm.showAppNavView)?_c('div',{staticClass:"subpage-nav"},[_vm._t("sub-nav",function(){return [(_vm.links.length > 0)?_c('Navbar',{attrs:{"navigationLinks":_vm.links}}):_vm._e()]})],2):_vm._e()])}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }